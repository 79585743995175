<template>
  <div class="login" v-if="!loading">
    <img class="logo" src="@/assets/img/my/buzzlogo@2x.png" alt="">
    <van-form>
      <div class="formItem"><img class="icon phone" src="@/assets/img/login/shouji@2x.png"/>手机号</div>
      <van-field v-model="loginData.mobile" @input="inputChange" maxlength="11" type="number" center
                 placeholder="请输入手机号"/>
      <div class="formItem active"><img class="icon" src="@/assets/img/login/yanzhengma@2x.png"/>验证码</div>
      <van-field v-model="loginData.smsCode" @input="inputChange" maxlength="4" type="number" center clearable
                 placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small" native-type="button" @click="sendCode" :disabled="isDisabled" type="primary">
            {{ sendCodeButtonText }}
          </van-button>
        </template>
      </van-field>
      <div class="checkbox_content">
        <van-checkbox v-model="checked" @change="inputChange" icon-size="15px" checked-color="#3E639A"
                      shape="square"></van-checkbox>
        <p @click="goAgreement">我已阅读并同意 <a href="/my/privacy?isUser=true">《服务协议》</a>和<a href="my/privacy">《隐私申明》</a></p>
      </div>
      <van-button round block type="info" :class="{applyBtn:true ,active:isActive}" :disabled="iSubmit" @click="login"
                  native-type="submit">{{ type != 2 ? '登录' : '提交' }}
      </van-button>
    </van-form>
  </div>
</template>

<script>
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'

  export default {
    name: "login",
    data() {
      return {
        checked: true,
        loading: true,
        isDisabled: false,
        iSubmit: false,
        sendCodeButtonText: '获取验证码',
        timeNumber: 60,
        isActive: false,
        timer: null,
        loginData: {
          mobile: '',
          smsCode: '',
        },
        pathUrl: '',
        type: 1
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => { //  这里的vm指的就是vue实例，可以用来当做this使用
        vm.pathUrl = from.fullPath
        // console.log(from)
      })
    },
    async created() {
      this.type = this.$route.query.loginType
      console.log(sessionStorage.backLink + ", login type: " + this.type)
      console.log(this.$route.query)

      if (this.type == 2){
        document.title = "绑定手机号"
        this.loading = false
      }else {
        this.$toast.loading("加载中...")
        this.loading = true

        if (!localStorage.sessionKey && untils.isWechat()) {
          const res = await wxLogin.getWechatAppId()
          await wxLogin.wxLogin(window.location.href, res.data.appId)
        } else if (untils.isWechat()){
          let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
          if (!UserInfo.phone || UserInfo.phone === '') {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }

        if (sessionStorage.backLink === '/login') {
          sessionStorage.backLink = '/'
        }

        let userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (userInfo && userInfo.openId && userInfo.phone) {
          console.log("back url: " + sessionStorage.backLink)

          if (sessionStorage.backLink === '/login') {
            await this.$router.replace('/')
          } else {
            await this.$router.replace(sessionStorage.backLink)
          }
        } else {
          console.log("login type：" + this.type)
          document.title = "绑定手机号"
          this.type = 2
          this.loading = false
          this.$toast.clear()
        }
      }
    },
    methods: {
      //跳转外部协议
      goAgreement() {
        this.$router.push("/my/privacy?isUser=true")
      },
      //发送验证码
      sendCode() {
        if (this.loginData.mobile.length == 0) {
          return false;
        }
        this.timer = setInterval(this.doLoop, 1000);
        this.isDisabled = true;
        this.$api.user.sendsms({
          "mobile": this.loginData.mobile
        }, res => {
          if (res.code == 200) {
            this.isDisabled = true;
          } else {
            clearInterval(this.timer);
            this.sendCodeButtonText = '获取验证码';
            this.isDisabled = false;
          }
        })
      },
      //调取登录接口
      login() {
        const query = this.$route.query
        //如果存在加入圈子
        if (query.project_id) {
          this.loginData.project_id = query.project_id
          this.loginData.channel_code = query.channel_code
          this.loginData.community_id = query.community_id
        }
        if (this.$route.query.wxCode) {
          this.loginData.wxCode = this.$route.query.wxCode
        }
        if (query.Surveytype || query.surveytype){
          this.loginData.surveytype = query.Surveytype || query.surveytype
        }
        if (this.$route.query.code) {
          this.loginData.wxCode = this.$route.query.code
        }
        if (this.$route.query.union_code) {
          this.loginData.union_code = this.$route.query.union_code
        }
        this.$api.user.bindmobile(this.loginData, res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          localStorage.setItem('sessionKey', res.data.sessionKey);

          if (sessionStorage.backLink) {
            if (sessionStorage.backLink === '/login') {
              this.$router.replace('/')
            } else {
              this.$router.replace(sessionStorage.backLink)
            }
          } else {
            this.$router.replace(this.pathUrl)
          }
        })
      },
      //倒计时开始
      doLoop() {
        this.timeNumber--;
        if (this.timeNumber > 0) {
          this.sendCodeButtonText = this.timeNumber + 'S';
        } else {
          clearInterval(this.timer); //清除js定时器
          this.isDisabled = false;
          this.sendCodeButtonText = '获取验证码';
          this.timeNumber = 60; //重置时间
        }
      },
      //输入框变化
      inputChange() {
        let mobileLength = this.loginData.mobile.length;
        let smsCodeLength = this.loginData.smsCode.length;
        let checked = this.checked;
        if (mobileLength == 11 && smsCodeLength == 4 && checked) {
          this.isTap(true);
        } else {
          this.isTap(false);
        }
      },
      isTap(off) {
        if (off) {
          this.isSubmit = false;
          this.isActive = true;
        } else {
          this.isSubmit = true;
          this.isActive = false;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .login {
    width: 100%;
    min-height: 1334px;
    background: #FFFFFF;
    padding: 130px 66px 80px;
  }

  .formItem {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;

    &.active {
      margin-top: 62px;
    }

    img {
      width: 32px;
      height: 36px;
      margin-right: 20px;
    }

    .phone {
      width: 28px;
      height: 40px;
    }

  }

  .logo {
    width: 260px;
    height: 92px;
    margin: 0 auto 126px;
    display: block;
  }

  .van-cell {
    width: 100%;
  }

  .van-field {
    padding: 0;
    padding-left: 52px;
    margin: 0 auto;
    height: 98px;
    color: #333333;
    font-size: 28px;
  }

  .van-field__control {
    text-indent: 30px;
  }

  .van-button {
    background: #fff;
    color: #3E639A;
    font-size: 28px;
    cursor: pointer;
    border: none;
  }

  .van-checkbox {
    margin-right: 15px;
  }

  .checkbox_content {
    font-size: 24px;
    display: flex;
    line-height: 30px;
    padding: 0 30px;
    margin: 46px auto 70px;

    p {
      font-weight: 400;
      color: #999999;
    }
  }

  .checkbox_content a {
    color: #3E639A;
  }

  .applyBtn {
    margin: 312px auto 0;
    width: 594px;
    border: none;
    height: 88px;
    background: #dcdcdc;
    color: #fff;
    cursor: pointer;
    font-size: 32px;
  }

  .applyBtn.active {
    background: #3E639A;
  }
</style>
